import {
  CHECKBOX_LEF, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const tableFields = {
    setting_notifications_id: {
      type: SELECT_INPUT,
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      label: 'Default Notification Profile',
      store: 'settingNotificationList',
      options: {
        value: 'id',
        label: 'label',
      },
      rules: 'required',
      placeholder: 'Select...',
    },
    setting_notifications_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: '',
    },
    minutes_to_log_out_an_inactive_user: {
      type: TEXT_INPUT,
      label: 'Minutes to log out an inactive user',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
  }

  return {
    tableFields,
  }
}
