
<template>

</template>
<script>
export default {
  name: "Skeleton",
};
</script>

<style scoped>

</style>