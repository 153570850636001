<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <b-form>
      <error-alert
        :fields="tableFields"
        :error="error"
      />
      <validation-observer
        ref="defaultNotificationUpdateVal"
      >
        <b-row>
          <b-col md="6">
            <component
              :is="tableFields[field].type"
              v-for="field in ['setting_notifications_id','minutes_to_log_out_an_inactive_user']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="settingNotification[field]"
              v-bind="getProps(field, tableFields)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <p
              class="text-left font-weight-bolder ml-3"
              style="margin-bottom: 5px;"
            >
              {{ $t('Default Status') }}
            </p>
            <component
              :is="tableFields[field].type"
              v-for="field in ['setting_notifications_active']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="settingNotification[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'home' }); "
              >
                {{ $t('Back to Home') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import defaultNotification from '@/store/user/default'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { required } from '@/libs/validations/validations'
import { ValidationObserver } from 'vee-validate'
import config from '../DefaultConfig'

export default {
  name: 'DefaultForm',
  components: {
    BForm, ErrorAlert, BButton, BCol, BRow, ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    settingNotification() {
      return this.$store.state[this.NOTIFICATION_MODULE_NAME].userNotificationDefaultsForm
    },
    settingNotificationClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingsUserDefaultsForm
    },
    isFormChanged() {
      return JSON.stringify(this.settingNotification) !== this.settingNotificationClone
    },
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    showConfirmationModalCancel() {
      this.$refs['bs-warning-modal-cancel'].show()
    },
    submit() {
      const params = {
        setting_notifications_active: this.settingNotification.setting_notifications_active,
        setting_notifications_id: this.settingNotification.setting_notifications_id.id,
        minutes_to_log_out_an_inactive_user: this.settingNotification.minutes_to_log_out_an_inactive_user,
      }
      this.error = {}
      this.onSubmit = true
      this.$refs.defaultNotificationUpdateVal.validate()
      this.sendNotification(this, params, `${this.NOTIFICATION_MODULE_NAME}/updateDefaultSettings`)
        .then(() => {
          this.$emit('refetch')
          this.clear()
        })
        .catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.onSubmit = false
        })
    },
    clear() {
      this.$refs.defaultNotificationUpdateVal.reset()
      this.$store.commit(`${this.NOTIFICATION_MODULE_NAME}/SET_FORM`, {})
    },
    loader() {
      this.$refs.defaultNotificationUpdateVal.reset()
      const smth = JSON.parse(this.settingNotificationClone)
      this.$store.commit(`${this.NOTIFICATION_MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.tableFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const NOTIFICATION_MODULE_NAME = 'settings-user-defaults'
    const MODULE_NAME_CLONE = 'cloneData'
    if (!store.hasModule(NOTIFICATION_MODULE_NAME)) store.registerModule(NOTIFICATION_MODULE_NAME, defaultNotification)

    const { tableFields } = config()
    return {
      MODULE_NAME_CLONE,
      NOTIFICATION_MODULE_NAME,
      tableFields,
    }
  },
}
</script>

<style scoped>
.table >>> .checkboxGroup {
  transform: translate(4%);
  padding: 0;
}

.table >>> .checkboxLabel {
  margin-left: -18px;
  margin-bottom: 1px;
}
</style>
