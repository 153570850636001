export default {
  GET(state, data) {
    state.userNotificationDefault = { ...data }
  },
  LIST(state, data) {
    state.userNotificationDefaults = data
  },
  SET_FORM(state, data) {
    state.userNotificationDefaultsForm = { ...data }
  },
}
