<template>
  <div>
    <skeletons v-if="loading" />
    <default-form
      v-else
      ref="default-form"
      @refetch="refetch"
    />
  </div>
</template>
<script>
import config from '@/views/settings/users/default/DefaultConfig'
import store from '@/store'
import userDefaultModule from '@/store/user/default'
import { onUnmounted } from '@vue/composition-api'
import Skeletons from './components/Skeleton.vue'
import DefaultForm from './components/DefaultForm.vue'

export default {
  name: 'Default',
  components: { Skeletons, DefaultForm },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loading = false
  },
  created() {
    this.$emit('updateMenu', 'settings-users-defaults')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.loading) this.$refs['default-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getDefaultSettings`).then(res => {
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, res.data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_DEFAULT_FORM_CLONE`, res.data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { tableFields } = config()
    const MODULE_NAME = 'settings-user'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, userDefaultModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      tableFields,
    }
  },
}
</script>

<style scoped>

</style>
